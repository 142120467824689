<template>
  <div>
    <MainCard>
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="faqs"
          :search="search"
          title="FAQ"
          subtitle="Halaman untuk menambahkan FAQ"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          :total-pages.sync="totalPages"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @check-description="checkDescription"
        ></DataTablePagination>
      </template>
    </MainCard>
    <ModalDialog
      :width="1080"
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah FAQ': 'Edit FAQ'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-model="faq.type"
            label="Type"
            outlined
            dense
            :items="types"
          ></v-autocomplete>
        </div>
        <div>
          <v-text-field
            v-model="faq.category"
            label="Kategori"
            outlined
            dense
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="faq.title"
            label="Title"
            outlined
            dense
            :loading="isLoadingData"
            :disabled="isLoadingData"
          ></v-text-field>
        </div>
        <div>
          <v-textarea
            v-model="faq.content"
            label="Deskripsi Faq"
            hide-details
            :loading="isLoadingData"
            :disabled="isLoadingData"
            outlined
          ></v-textarea>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      :hidden-actions="false"
      header="Deskripsi FAQ"
      @close-button="modalDescription = false"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p>{{ detail.content }}</p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus FAQ"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog.vue'
import DataTablePagination from '../components/DataTablePagination.vue'
import MainCard from '../components/MainCard.vue'
import ModalDialog from '../components/ModalDialog.vue'

export default {
  name: 'Faq',
  components: {
    DataTablePagination,
    ModalDialog,
    MainCard,
    ConfirmDialog,
  },
  data() {
    return {
      widthModal: 810,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      isLoadingData: true,
      isLoadingButton: false,
      isLoadingTable: false,
      search: '',
      faqs: [],
      faq: {
        title: '',
        category: '',
        content: '',
        type: '',
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Kategori', value: 'category' },
        { text: 'Judul', value: 'title' },
        { text: 'Isi', value: 'faq_content' },
        { text: 'Aksi', value: 'actions' },
      ],
      types: ['All', 'Company', 'User', 'School'],
      modalDialog: false,
      modalDescription: false,
      confirmDialog: false,
      dialog: '',
      service: 'faq',
      detail: { content: '' },
      tempContent: '',
      tempImage: [],
      formValid: false,
    }
  },
  computed: {
    initialContent: {
      get() {
        if (this.faq.content === null) {
          return ''
        }

        return this.faq.content
      },
      set() {
        return this.faq.content
      },
    },
  },
  watch: {
    faq: {
      handler() {
        const valid = []
        const requiredField = ['title', 'category', 'content', 'type']
        Object.entries(this.faq).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
  },
  async mounted() {
    this.get()
  },
  methods: {
    showFormAdd() {
      this.tempContent = ''
      this.tempImage = []
      this.dialog = 'add'
      this.modalDialog = true
      this.resetForm()
    },
    resetForm() {
      this.faq.type = []
      this.faq.category = ''
      this.faq.title = ''
      this.faq.content = ''
    },
    async get(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, { ...params, page: this.page }).then(
        ({ data }) => {
          this.faqs = data.data.map((faq, index) => ({
            ...faq,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
          this.isLoadingData = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingData = false
    },
    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.service, this.faq).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.modalDialog = false
      this.resetForm()
      await this.get()
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      this.isLoadingData = true
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.faq = data.data
      })
      this.isLoadingData = false
    },
    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update(this.service, this.faq, this.faq.uuid).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
      })
      this.isLoadingButton = false
      this.modalDialog = false
      await this.get()
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.faq.uuid = uuid
      this.confirmDialog = true
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.faq.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.confirmDialog = false
      await this.get()
    },
    async checkDescription(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.detail = data.data
      })
      this.modalDescription = true
    },
    async searchHandler(search) {
      await this.get({ search })
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
